import { STORAGE_KEY } from '../const';

let cacheCart = undefined;

const cartItemCount = (cart) => {
  if (!cart) return 0;
  return Object.values(cart.products)
    .map((p) => p.quantity)
    .reduce((a, b) => a + b, 0);
};

const getCart = (shopId) => {
  if (cacheCart) {
    return cacheCart;
  }
  const cartStr = localStorage.getItem(`${shopId}-${STORAGE_KEY.CART}`);
  const cart = JSON.parse(cartStr) || {};
  if (!cart.products) {
    cart.products = {};
  }
  cacheCart = cart;
  return cart;
};

function reduceFromCart(shopId, product, quantity) {
  const cart = getCart(shopId);
  const existingCartProduct = getCartProduct(cart, product.id);
  if (existingCartProduct) {
    const updatedQuantity = existingCartProduct.quantity - quantity;
    if (updatedQuantity <= 0) {
      delete cart.products[product.id];
    } else {
      cart.products[product.id] = {
        product,
        quantity: updatedQuantity,
      };
    }
    _updateCartSummary(cart);
    saveCart(cart, shopId);
  }
  return cart;
}

function removeFromCart(shopId, product) {
  const cart = getCart(shopId);
  const existingCartProduct = getCartProduct(cart, product.id);
  if (existingCartProduct) {
    delete cart.products[product.id];
    _updateCartSummary(cart);
    saveCart(cart, shopId);
  }
}

const addToCart = (shopId, product, quantity) => {
  const cart = getCart(shopId);
  const existingCartProduct = getCartProduct(cart, product.id);
  if (existingCartProduct) {
    cart.products[product.id] = {
      product,
      quantity: existingCartProduct.quantity + quantity,
    };
  } else {
    cart.products[product.id] = { product, quantity };
  }
  _updateCartSummary(cart);
  saveCart(cart, shopId);
  return cart;
};

function setQtyInCart(shopId, product, quantity) {
  const cart = getCart(shopId);
  const existingCartProduct = getCartProduct(cart, product.id);
  if (existingCartProduct) {
    if (quantity <= 0) {
      delete cart.products[product.id];
    } else {
      cart.products[product.id] = {
        product,
        quantity,
      };
    }
  }
  _updateCartSummary(cart);
  saveCart(cart, shopId);
  return cart;
}

function getCartProduct(cart, productId) {
  return cart.products[productId];
}

function _updateCartSummary(cart) {
  let total = 0;
  let totalSavings = 0;
  Object.keys(cart.products).forEach((productId) => {
    const cartProduct = cart.products[productId];
    total += cartProduct.product.price * cartProduct.quantity;
    if (cartProduct.product.mrp) {
      totalSavings += (cartProduct.product.mrp - cartProduct.product.price) * cartProduct.quantity;
    }
  });
  cart.summary = { total, totalSavings };
}

function saveCart(cart, shopId) {
  cacheCart = cart;
  const cartStr = JSON.stringify(cart);
  localStorage.setItem(`${shopId}-${STORAGE_KEY.CART}`, cartStr);
}

function clearCart(shopId) {
  cacheCart = { products: {} };
  localStorage.removeItem(`${shopId}-${STORAGE_KEY.CART}`);
}

const CartHelper = {
  cartItemCount,
  getCart,
  addToCart,
  clearCart,
  removeFromCart,
  reduceFromCart,
  setQtyInCart,
};

export default CartHelper;
