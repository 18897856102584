import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Vegetable from './Vegetables.jpg';
import phone from './phone.jpg';
import whatsapp from './whatsapp.png';
import facebook from './facebook.png';
import styles from './styles.module.css';

export default function PageNotFound() {
  const history = useHistory();

  return (
    <Grid item xs={6} sm={3} md={2}>
      <div className={styles.mainDiv}>
        <div className={styles.banner}>
          <div className={styles.bannerMain}>
            <div className={styles.welcome}>
              <b>Page Not Found!</b>
            </div>
            <div className={styles.flexRow}>
              <div className={styles.bannerDiv}>
                <div className={styles.bannerDivLeft}>
                  <div className={styles.bannerTitle}>
                    <h1>Eazy Cart</h1>
                    <h3>നിങ്ങൾക്കും ഓൺലൈൻ cart </h3>
                    <h3>തുടങ്ങാം.</h3>
                  </div>
                </div>
                <div className={styles.bannerDivleft}>
                  <img alt="" className={styles.bannerImg} src={Vegetable} />
                </div>
              </div>
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('./1')}
                alt=""
                className={styles.bannerImg}
                src={phone}
              />

              <div style={{ display: 'flex' }}>
                <img
                  onClick={() => {
                    window.open('https://wa.link/wy6nie');
                  }}
                  className={styles.whatsappIcon}
                  src={whatsapp}
                  alt=""
                />
                <span>Whatsapp</span>
              </div>
              <div style={{ display: 'flex' }}>
                <img
                  onClick={() => {
                    window.open('https://www.facebook.com/sanoft');
                  }}
                  className={styles.whatsappIcon}
                  src={facebook}
                  alt=""
                />
                <span>facebook</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}
