import React, { useEffect, useContext, useState } from 'react';
import config from '../../config';
import ProductService from '../../services/ProductService';
import CategoryView from './CategoryView';
import { ShopContext } from '../../contexts/ShopContext';

const styles = {
  categoriesWrapper: {
    padding: '8px',
    display: 'flex',
    width: 'auto',
    overflowY: 'hidden',
    height: '140px',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    flex: 'none',
    boxShadow: '0 4px 2px -2px #ececec',
    borderBottom: '1px solid #ececec',
    backgroundColor: '#fff',
  },
};

const allCategory = {
  id: 'all',
  name: 'ALL',
  selected: true,
  image: 'https://storage.googleapis.com/sanoft-eazy-cart.appspot.com/static/images/all_cateogries.svg',
};

export default function CategoryListView({ fcnSetSelectedCategory }) {
  const { shop } = useContext(ShopContext);
  const [categories, setCategories] = useState([]);
  const [categoriesShopId, setCategoriesShopId] = useState(null);

  const categorySelected = (category) => {
    fcnSetSelectedCategory(category);
    updateSelectedCategory(category);
  };

  const updateSelectedCategory = (category) => {
    const _categories = [...categories];
    _categories.forEach((c) => (c.selected = false));
    _categories.find((c) => c.id === category.id).selected = true;
    setCategories(_categories);
  };

  useEffect(() => {
    if (!shop || !shop.shop_id) {
      setCategories([]);
      return;
    }

    if (categoriesShopId && shop.shop_id === categoriesShopId) {
      return;
    }

    const loadCategories = async () => {
      try {
        const _categories = await ProductService.getCategories(shop);
        const shopConfig = shop?.meta || config.meta;
        if (shopConfig.allCategoryRequired) {
          _categories.unshift(allCategory);
        } else if (_categories.length) {
          const category = _categories[0];
          category.selected = true;
          fcnSetSelectedCategory(category);
        }
        setCategories(_categories);
        setCategoriesShopId(shop.shop_id);
      } catch (error) {
        console.error(error.message);
      }
    };
    loadCategories();
  }, [shop, fcnSetSelectedCategory, categoriesShopId]);

  return (
    <div style={styles.categoriesWrapper}>
      {categories.map((category) => {
        return <CategoryView key={category.id} category={category} shop={shop} categorySelected={categorySelected} />;
      })}
    </div>
  );
}
