import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import OrderService from '../../../services/OrderService';
import { Grid, LinearProgress, Button } from '@mui/material';
import { MAX_API_CALLS_FOR_POLLING, STORAGE_KEY, TIME_OUT_MESSAGE, ROUTES, QR_PREFIX } from '../../../const';
import styles from './style.module.css';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import QRCode from 'qrcode.react';
import { OrderContext } from '../../../contexts/OrderContext';
import { ShopContext } from '../../../contexts/ShopContext';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CartContext } from '../../../contexts/CartContext';
import { handlePayment } from '../../utils/HandlePayment';

function PaymentStatus() {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const paymentId = queryParams.get('payment_id');
  const secret = queryParams.get('secret');

  const { countOrders } = useContext(OrderContext);
  const { shop, theme } = useContext(ShopContext);
  const { clearCartItems } = useContext(CartContext);

  const [orderData, setOrderData] = useState(null);
  const [apiCallCount, setApiCallCount] = useState(0);
  const [error, setError] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);

  const saveOrderToLocalStorage = useCallback((order) => {
    const orders = JSON.parse(localStorage.getItem(STORAGE_KEY.ORDERS) || '[]');
    const updatedOrders = orders.filter(({ id }) => id !== order.id).concat(order);
    localStorage.setItem(STORAGE_KEY.ORDERS, JSON.stringify(updatedOrders));
  }, []);

  useEffect(() => {
    setError(queryParams.get('error'));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        const data = await OrderService.getOrderByPayment(shop?.shop_id, paymentId, { payment_secret: secret });
        if (data) {
          setOrderData(data);
          saveOrderToLocalStorage(data);
          countOrders();
          clearCartItems(shop.shop_id);
        } else {
          setApiCallCount((prevCount) => prevCount + 1);
        }
      } catch (err) {
        setError(err.message || 'An error occurred while checking payment status');
      }
    };

    if (error || showLoading || isRetrying) return;

    if (!orderData && apiCallCount < MAX_API_CALLS_FOR_POLLING) {
      const interval = setInterval(() => {
        if (!orderData && apiCallCount < MAX_API_CALLS_FOR_POLLING) {
          checkPaymentStatus();
        } else if (apiCallCount === MAX_API_CALLS_FOR_POLLING) {
          setError(TIME_OUT_MESSAGE);
          clearInterval(interval);
        } else {
          clearInterval(interval);
        }
      }, 4000);

      return () => {
        clearInterval(interval);
      };
    } else if (apiCallCount === MAX_API_CALLS_FOR_POLLING) {
      setError(TIME_OUT_MESSAGE);
    }
  }, [paymentId, apiCallCount, countOrders, shop?.shop_id, orderData, saveOrderToLocalStorage, secret, error,clearCartItems, showLoading, isRetrying]);

  const handleBackToOrders = () => {
    history.push(ROUTES.ORDERS.replace(':shopId', shop?.shop_id));
  };

  const handleBackToHome = () => {
    history.push(ROUTES.SHOP_HOME.replace(':shopId', shop?.shop_id));
  };

  const handleGoBack = () => {
    history.push(ROUTES.SHOP_HOME.replace(':shopId', shop?.shop_id));
  };


  const retryPayment = async () => {
    setShowLoading(true);
    setIsRetrying(true);
    setApiCallCount(0);
    setError(null);
    try {
      await handlePayment({
        shop,
        customerDetails: null,
        setShowLoading,
        setError,
      });
    } catch (err) {
      setError(err.message || 'An error occurred during payment retry.');
    } 
  };
  
  return (
    <div className={styles.container}>
      {showLoading ? (
        <Grid style={{ maxWidth: 500 }}>
          <p style={{ paddingBottom: 20 }}>Please wait....</p>
          <LinearProgress />
        </Grid>
      ) : error ? (
        <Grid display="flex" flexDirection="column" alignItems="center">
          <p className={styles.error}>{error}</p>
          <Grid display="flex" flexDirection="row" gap={3} alignItems="center">
            <Button className={styles.button} onClick={handleGoBack}>
              Go Back
            </Button>
            <Button className={styles.button} onClick={retryPayment}>
              Retry Payment
            </Button>
          </Grid>
        </Grid>
      ) : !orderData ? (
        <Grid style={{ maxWidth: 500 }}>
          <p style={{ paddingBottom: 20 }}>Verifying Payment .....</p>
          <LinearProgress />
        </Grid>
      ) : (
        <Grid
          className={styles.statusContainter}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <DoneAllIcon className={styles.icon} />
          <span className={styles.heading}>Your order placed successfully</span>
          <QRCode value={QR_PREFIX + JSON.stringify({ id: orderData?.id, secret: orderData?.secret })} size={180} />
          <Grid container rowSpacing={1} className={styles.orderContainer}>
            <Grid item xs={12}>
              <span className={styles.subHead}>Order details</span>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <span className={styles.subText}>Order No :</span>
              <span className={styles.text}>{orderData?.id}</span>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <span className={styles.subText}>Order Date :</span>
              <span className={styles.text}>{DateTimeHelpers.getDate(orderData?.created_at)}</span>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <span className={styles.subText}>Customer name :</span>
              <span className={styles.text}>{orderData?.details?.customer?.name}</span>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <span className={styles.subText}>Phone No :</span>
              <span className={styles.text}>{orderData?.details?.customer?.mobile}</span>
            </Grid>
            <Grid item container rowSpacing={1} xs={12} pt={1} mt={0.5}>
              <Grid item xs={4} display="flex" justifyContent="center" className={styles.title}>
                <span>Items</span>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="center" className={styles.title}>
                <span>Qty</span>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="center" className={styles.title}>
                <span>Total</span>
              </Grid>
              {orderData?.details?.items.map((item) => (
                <>
                  <Grid item xs={4} display="flex" justifyContent="center">
                    <span className={styles.text}>{item.name}</span>
                  </Grid>
                  <Grid item xs={4} display="flex" justifyContent="center">
                    <span className={styles.text}>x{item.quantity}</span>
                  </Grid>

                  <Grid item xs={4} display="flex" justifyContent="center">
                    <span className={styles.text}>{item.rate * item.quantity}</span>
                  </Grid>
                </>
              ))}
              <Grid item xs={4} mt={1} display="flex" justifyContent="center" className={styles.title}>
                <span>Subtotal</span>
              </Grid>
              <Grid item xs={4} mt={1} display="flex" justifyContent="center" className={styles.title}>
                <span></span>
              </Grid>
              <Grid item xs={4} mt={1} display="flex" justifyContent="center" className={styles.title}>
                <span>{orderData?.details?.amount}</span>
              </Grid>
            </Grid>
          </Grid>
          <span className={styles.subTitle}>Thank you so much for your order</span>
          <button
            className={styles.btn}
            style={{ backgroundColor: theme?.primary ?? '#00923e' }}
            onClick={window.print}
          >
            Download QR
          </button>
          <Grid display="flex" columnGap={2}>
            <button className={styles.btn} style={{ backgroundColor: theme?.secondary }} onClick={handleBackToHome}>
              Home
            </button>
            <button className={styles.btn} style={{ backgroundColor: theme?.secondary }} onClick={handleBackToOrders}>
              Orders
            </button>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default PaymentStatus;
