export const SHOP_SOURCE = {
  YESCLOUD: 'yescloud',
  API: 'API',
};

export const STORAGE_KEY = {
  CART: 'cart',
  CUSTOMER_ID: 'customer-id',
  ORDERS: 'orders',
  CUSTOMER_DETAILS: 'customer-details',
};

export const SERVER = {
  URL:
    process.env.REACT_APP_API_BASE_URL ||
    // 'https://sanoft-mini-erp.uc.r.appspot.com/api',
    // 'http://localhost:3000/api',
    'https://api-pos-legacy.sanoft.com',
};

export const DEFAULT_CURRENCY = 'Rs';

export const ROUTES = {
  SHOP_HOME: '/:shopId',
  ORDERS: '/:shopId/orders',
  ORDER_DETAILS: '/:shopId/orders/:orderId',
  PAYMENT_STATUS: '/:shopId/payment-status',
  PRODUCT_DETAILS: '/:shopId/:categoryId/:productId',
  CONTACT_US: '/:shopId/contact-us',
};

export const DEFAULT_HOST = 'cart.sanoft.com';

export const DOMAIN_CONFIG = {
  'ulcc.cart.sanoft.com': 2445,
  'www.avocart.in': 438
}

export const THEME_CONFIG = {
  '2445': {
    name: 'ULCP',
    shop_id: 2445,
    theme: {
      primary: '#00923e',
      secondary: '#236632',
      tertiary: '#3c2a21',
    },
    config: {
      currency: '₹',
    },
  },
  '438': {
    name: 'AVOCART',
    shop_id: 438,
    theme: {
      primary: '#88113F',
      secondary: '#88143F',
      tertiary: '#89113F',
    },
    config: {
      currency: '₹',
    },
  },
};

export const DEFAULT_CONFIG = {
  name: 'SANOFT',
  shop_id: null,
  theme: {
    primary: '#272C86',
    secondary: '#1F236B',
    tertiary: '#9DA2FF',
  },
  config: {
    currency: '₹',
  },
};

export const MAX_API_CALLS_FOR_POLLING = 75;

export const TIME_OUT_MESSAGE = 'Payment failed. If payment is deducted, please contact the counter.';

export const QR_URL = 'https://qr.sanoft.com/oo?d=';

export const QR_PREFIX = 'oo:';

export const SANOFT_WEB_URLS = {
  PRIVECY_POLICY: 'https://www.sanoft.com/privacy-policy',
  TERMS: 'https://www.sanoft.com/terms-and-conditions',
  REFUND_POLICY: 'https://www.sanoft.com/refund-policy',
  CONTACT_US: 'https://www.sanoft.com/#contact-us',
};

export const ORDER_STATUS = {
  PENDING: 'PENDING',
  DELIVERED: 'DELIVERED',
};

export const ORDER_TYPE = {
  default: [
    {
      id: 'name',
      label: 'Name',
      order: 1,
      type: 'text',
      required: true,
      editable: true,
    },
    {
      id: 'mobile',
      label: 'Mobile',
      order: 2,
      type: 'tel',
      required: true,
      editable: true,
    },
  ],
  'drive-through': [
    {
      id: 'car_name',
      label: 'Car Name',
      order: 1,
      type: 'text',
      required: true,
      editable: true,
    },
    {
      id: 'car_model',
      label: 'Car Model',
      order: 2,
      type: 'text',
      required: true,
      editable: true,
    },
    {
      id: 'name',
      label: 'Name',
      order: 3,
      type: 'text',
      required: true,
      editable: true,
    },
    {
      id: 'mobile',
      label: 'Mobile',
      order: 4,
      type: 'tel',
      required: true,
      editable: true,
    },
  ],
  table: [
    {
      id: 'table',
      label: 'Table',
      order: 1,
      type: 'text',
      required: true,
      editable: false,
    },
    {
      id: 'name',
      label: 'Name',
      order: 2,
      type: 'text',
      required: false,
      editable: true,
    },
    {
      id: 'mobile',
      label: 'Mobile',
      order: 3,
      type: 'tel',
      required: false,
      editable: true,
    },
  ],
};
