import axios from 'axios';
import { SERVER } from '../const';

async function getCategories(shop) {
  const resp = await axios.get(`${SERVER.URL}/api/public/shops/${shop.shop_id}/categories/`);
  return resp.data.data;
}

async function getCategory(shop, categoryId) {
  const resp = await axios.get(`${SERVER.URL}/api/public/shops/${shop.shop_id}/categories/${categoryId}/`, {
    params: { shop_id: shop.shop_id },
  });
  return resp.data.data;
}

async function getProducts(shop, category, searchKey) {
  const categoryId = category && category.id !== 'all' ? category.id : undefined;

  const resp = await axios.get(`${SERVER.URL}/api/public/shops/${shop.shop_id}/items/`, {
    params: { category: categoryId, searchKey },
  });
  const products = resp.data.data.map((product) => {
    return {
      id: product.id,
      images: [product.image],
      name: product.name,
      price: product.price || product.mrp,
      mrp: product.mrp || product.price,
      stock: product.stock,
      unit: product.unit,
    };
  });
  return products;
}

async function getProduct(shop, productId) {
  const resp = await axios.get(`${SERVER.URL}/api/public/shops/${shop.shop_id}/items/${productId}/`);
  return resp.data.data;
}

const ProductService = {
  getProducts,
  getCategories,
  getCategory,
  getProduct,
};

export default ProductService;
