import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import ProductView from '../ProductView/ProductView';
import ProductService from '../../../services/ProductService';
import Info from '../Alerts/Info';
import { ShopContext } from '../../../contexts/ShopContext';
import Loader from '../Loading';

const styles = {
  productsListParent: {
    margin: 10,
    minHeight: 100,
    position: 'relative',
  },
};

export default function ProductListView({ selectedCategory, searchKey, fnOnResultChange, fnOpenProductDetailsPage }) {
  const { shop } = useContext(ShopContext);
  const [products, setProducts] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const loadProducts = useCallback(
    async (selectedCategory, searchKey) => {
      try {
        if (!selectedCategory && !shop?.meta?.allCategoryRequired && !searchKey) {
          return;
        }
        setLoading(true);
        const products = await ProductService.getProducts(shop, selectedCategory, searchKey);
        products.sort((a, b) => {
          if (a.stock <= 0 && b.stock > 0) {
            return 1;
          } else if (a.stock > 0 && b.stock <= 0) {
            return -1;
          } else {
            return 0;
          }
        });

        setProducts(products);
        setLoading(false);

        // Sending the search result count
        fnOnResultChange?.(products.length);
      } catch (error) {
        console.error(error);
      }
    },
    [shop, fnOnResultChange]
  );

  useEffect(() => {
    setProducts(null);
    loadProducts(selectedCategory, searchKey);
  }, [selectedCategory, searchKey, loadProducts]);

  return (
    <div style={styles.productsListParent}>
      <Loader isOpen={isLoading} backdropColor="#fff" />
      {products == null ? (
        <div></div>
      ) : products.length ? (
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="streach">
          {products.map((product) => (
            <ProductView
              key={product.id}
              category={selectedCategory}
              product={product}
              fnOpenProductDetailsPage={fnOpenProductDetailsPage}
            />
          ))}
        </Grid>
      ) : (
        <Info title={'No Items Found'} content={'There is no items in this category.'} />
      )}
    </div>
  );
}
