import { createContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DEFAULT_CONFIG, DOMAIN_CONFIG, THEME_CONFIG } from '../const';

export const ShopContext = createContext();

export function ShopProvider(props) {
  const history = useHistory();
  const location = useLocation();
  const shopIdFromUrl = location.pathname.split('/')[1];
  const shopIdFromWindow = (window.sanoft || {}).shopId;

  const [shop, setShop] = useState(null);
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    const hostname = window.location.hostname;
    const shopId = shopIdFromWindow || DOMAIN_CONFIG[hostname] || shopIdFromUrl;

    if (!shopId) {
      console.warn('Shop ID is missing or invalid. Falling back to default configuration.');
    }

    const config = THEME_CONFIG[shopId] || DEFAULT_CONFIG;

    if (shopId && shopId !== shopIdFromUrl) {
      history.replace(`/${shopId}`);
    }

    setShop({ ...config, shop_id: shopId });
    setTheme(config.theme);
  }, [shopIdFromUrl, shopIdFromWindow, history]);

  return <ShopContext.Provider value={{ shop, setShop, theme }}>{props.children}</ShopContext.Provider>;
}
