import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Paper } from '@material-ui/core';
import { ReactComponent as AddShoppingCart } from './AddShoppingCart.svg';
import { ReactComponent as ReduceShoppingCart } from './ReduceShoppingCart.svg';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import styles from './ProductView.module.css';
import commonStyles from '../../../common.module.css';
import { getProductImageSrc } from '../../../helpers/ProductHelper';
import { CartContext } from '../../../contexts/CartContext';
import { ShopContext } from '../../../contexts/ShopContext';
import { DEFAULT_CURRENCY } from '../../../const';

export default function ProductView(props) {
  const { product, category, fnOpenProductDetailsPage } = props;
  const { shop, theme } = useContext(ShopContext);
  const { cart, itemsCount, addItemToCart, reduceItemFromCart } = useContext(CartContext);
  const [productQuantity, setProductQuantity] = useState(0);

  const addToCartBtnPressed = () => {
    addItemToCart(product);
  };

  const reduceFromCartBtnPressed = () => {
    reduceItemFromCart(product);
  };

  const openProductDetailsPage = () => {
    fnOpenProductDetailsPage({ shop, category, product, cart });
  };

  useEffect(() => {
    if (!itemsCount && !cart) return;
    const cartProduct = cart.products[product.id];
    setProductQuantity(cartProduct?.quantity || 0);
  }, [itemsCount, cart, product?.id]);

  return (
    <Grid item xs={6} sm={3} md={2}>
      <Paper elevation={1} className={styles.productCard}>
        <div className={styles.productCardTop} onClick={openProductDetailsPage}>
          <div className={styles.productImgParent}>
            <img className={styles.productImage} src={getProductImageSrc(product)} alt={product.name} />
          </div>
          <div className={styles.flexParent}>
            <div className={styles.flexLeft}>
              <h3 className={commonStyles.twoLineEllipsise}>{product.name}</h3>
              <div className={commonStyles.twoLineEllipsise} style={{ marginTop: 5 }}>
                {product.description}
              </div>
            </div>
            <div className={styles.footer}>
              <span>
                {shop?.config?.currency || DEFAULT_CURRENCY} {product.price}
              </span>
              {product.mrp !== product.price && (
                <span className={styles.priceOld}>
                  {shop?.config?.currency || DEFAULT_CURRENCY} {product.mrp}
                </span>
              )}
            </div>
          </div>

          {product.stock <= 0 && (
            <div className={styles.soldOut}>
              <span className={styles.soldOutText}>SOLD OUT</span>{' '}
            </div>
          )}
        </div>
        <div className={styles.cartButton} style={{ backgroundColor: theme?.primary }}>
          {productQuantity <= 0 ? (
            <Button onClick={addToCartBtnPressed} className={styles.buttonAddToCart}>
              <span className={styles.addToCartBtnText}>Add to Cart</span>
            </Button>
          ) : (
            <React.Fragment>
              <Button onClick={reduceFromCartBtnPressed}>
                <ReduceShoppingCart style={{ color: red[500] }} />
              </Button>
              <div className={styles.cartBtnText}>
                <span>{productQuantity}</span>
              </div>
              <Button onClick={addToCartBtnPressed}>
                <AddShoppingCart style={{ color: green[500] }} />
              </Button>
            </React.Fragment>
          )}
        </div>
      </Paper>
    </Grid>
  );
}
